/* Form.module.css */

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    height: 100%;
    background-image: url(../images/e363eed80b856fcfb336a0f2ff34653642096f98.jpg);  
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    animation: scrollBackground 25s linear infinite;
    
  }
  
  select {
    width: 100%;
    padding: 10px 20px;
    margin: 10px 0;
    border: none;
    border-radius: 25px;
    background-color: #f2f2f2;
    font-size: 16px;
    color: #555;
    appearance: none;
  }
  
  select:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  }
  
  option {
    font-size: 16px;
    color: #555;
  }
  
  body {
    background-color: rgba(255, 255, 255, 0.956);
    direction: rtl;
    
  }
  
  @keyframes scrollBackground {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  
  
  
  
  .container {
    background-color: rgb(88, 128, 162);
  }
  
  
  
  .inputContainer {
    height: "100vh";
    background-color: rgb(88, 128, 162);
     display: "flex";
     justify-content: "center";
      align-items: "center"; 
  }
  .inputContainer2 {
    display: grid;
    grid-template-columns: 1fr ; /* This creates two columns */
    gap: 20px; /* This adds some space between grid items */
    width: 90%;
    padding: 20px;
    border-radius: 12px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    margin-top: 20px; /* adjust as needed */
    margin-bottom: 20px;
    background-color: rgba(255, 255, 255, 0.839);
  }
  @media (max-width: 768px) {
    .inputContainer {
      grid-template-columns: 1fr; /* Switch to single column for mobile devices */
     align-items: center;
     padding: 25px;
    }
    .input {
      width: 100%; /* Take up more width on smaller screens */
      color: sandybrown;
      padding: 12px 20px !important;
      align-self: center;
      margin: 7px;
      padding: 25px;
  
    }
    .inputGroup2 {
      display: flex;
      flex-direction: column;
      width: 70%;
    }
  .button{
    margin: 20px auto;
  }
  .inputGroup{
    margin-right: 7%;
  }
  .title2{
  align-self:center;
  font-size: smaller;
  
  }
  
  
  
  
  }
  .title2{
    color:#f20606;
   text-Align: 'center';
    margin-Top: '20px';
    font-Size:14;
  }
  .label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: right;
    padding: 10px;
  }
  
  .input {
    width: 120%;
    padding: 8px 12px;
    border: 2px solid #ccc;
    border-radius: 80px;
    box-sizing: border-box;
    text-align: right;
    padding: 10px;
  }
  .inputRow {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
   
  }
  .inputGroup {
    display: flex;
    flex-direction: column;
    width: 70%;
   
  }
  .space{
    padding: 10px;;
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    height: 100%;
  }
  
  .button {
    width: 100%;
    max-width: 200px;
    background-color: #696b6e;
    color: white;
    padding: 20px 20px;
    /* position:absolute; */
  
    margin: 20px auto;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    align-self:center;
    margin-right: 0%;
  
  }
  
  
  
  .button:hover {
    background-color: #45a049;
  }
  
  
  
  
  